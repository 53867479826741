<template>
  <div class="container">
    <div id="about-us" class="floating-container">
      <h4 class="h4">About Us</h4>
      <p>
        SRE is a construction firm serving since 1996 in the field of C&I,
        Electrical, Mechanical and Civil to erection and maintenance. Over the
        years, the company has earned a reputation for excellence and
        professionalism for continuously striving to meet - and even exceed -
        standards which set the climate for a sustainable business relationship
        with its clients.<br /><br />
        We have a broad portfolio of successfully completed projects across a
        range of sectors including C&I, Electrical, Mechanical and Civil to
        erection and maintenance. We have built our reputation on our client
        focus and hands-on approach. We have a strong reputation for our
        competitive edge and reliable execution of all projects undertaken.
        <br /><br />
        This is achieved through our relentless attention to detail and an
        experienced team with the drive and customer focus to ensure client
        satisfaction is a given on all SRE projects. We believe in the strength
        of the team. And that this makes the difference when it comes to making
        amazing things possible.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutServices"
};
</script>

<style scoped>
.container {
  background: #f9f9f9;
  display: flex;
  justify-content: center;
}

.floating-container {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

  max-width: 60rem;
  padding: 3rem 4rem;
  transform: translateY(-3rem);
}

p {
  padding-bottom: 2rem;
}

@media screen and (max-width: 800px) {
  .floating-container {
    box-shadow: none;
    border-bottom: 1px solid #eee;
    transform: none;
  }
}
</style>
