<template>
  <div class="container" id="contact-us">
    <div class="wrapper">
      <h4 class="h4">Contact Us</h4>
      <p class="para">
        Let's start a conversation. We'd love to hear from you.
      </p>

      <div class="contact-wrapper">
        <div class="contact">
          <div class="contact-type-title">
            OFFICE
          </div>
          <div class="contact-info">
            Usha Complex <br />
            Opposite ANS College<br />
            NH-31, Barh, Patna, Bihar, IN <br />
            803213
          </div>
        </div>

        <div class="contact">
          <div class="contact-type-title">
            PHONE
          </div>
          <div class="contact-info">+916132356166<br /></div>
        </div>

        <div class="contact">
          <div class="contact-type-title">
            EMAIL
          </div>
          <a
            href="mailto:srelectricalskahalgaon@gmail.com"
            class="contact-info"
          >
            srelectricalskahalgaon@gmail.com
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUs"
};
</script>

<style scoped>
.para {
  text-align: center;
}
.container {
  background: #f7f7f7;
  display: flex;
  justify-content: center;
  padding: 2rem 3rem;
}
.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
}

.contact-wrapper {
  max-width: 1300px;
  width: 100%;
  padding: 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact {
  margin: 0 3rem 3rem 0;
  max-width: 15rem;
  width: 100%;
}
.contact-type-title {
  font-weight: 800;
  color: #f00;
  margin-bottom: 0.5rem;
}

.contact-info {
  color: #555;
  text-decoration: none;
}
</style>
