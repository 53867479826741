<template>
  <div class="container" id="projects">
    <div class="wrapper">
      <div class="project-section">
        <div class="desp-container">
          <h4 class="h2">Projects</h4>
          <p class="desp-para">
            Every project we deliver is an opportunity for us to bring our
            unique thinking to solve problems, innovate and collaborate with our
            clients and beyond.
          </p>
        </div>
        <div class="projects-card-container">
          <OurProjectsCarousel />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OurProjectsCarousel from "./OurProjectsCarousel.vue";

export default {
  name: "OurProjects",
  components: {
    OurProjectsCarousel
  }
};
</script>

<style scoped>
.project-section {
  display: flex;
  max-width: 1300px;
  padding: 0 2rem;
  width: 100%;
}

.h2 {
  color: #fff;
  margin-bottom: 2rem;
}

.desp-para {
  font-size: 22px;
  font-weight: 800;
}

.desp-container {
  width: 100%;
  max-width: 25rem;
}

.projects-card-container {
  padding-left: 4rem;
  flex: 1;
  max-width: 50rem;
}

.container {
  background: #092737;
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 2rem 3rem;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 4rem 0;
}

@media screen and (max-width: 1200px) {
  .project-section {
    align-items: center;
    flex-direction: column;
  }

  .projects-card-container {
    margin-top: 2rem;
    padding: 0;
  }

  .desp-para,
  .h2 {
    text-align: center;
  }
}
</style>
