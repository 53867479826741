<template>
  <div class="card-container">
    <div class="date-container">
      {{ project.year }}
    </div>
    <div class="content-container">
      <div class="title-container">
        <span>{{ project.title }} </span>
      </div>
      <p>
        {{ project.desp }}
      </p>
    </div>
    <div class="status-bar">
      {{ project.status }}
    </div>
  </div>
</template>

<script>
export default {
  name: "OurProjectsCard",
  props: ["project"]
};
</script>

<style scoped>
.card-container {
  background: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  height: 25rem;
  position: relative;
  width: 15rem;
  text-align: left;
}

.date-container {
  background: #005bff;
  color: #fff;
  font-weight: 800;
  padding: 0.5rem 1rem;
  position: absolute;
  right: 1rem;
}

.content-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 3rem 2rem;
}

.title-container {
  font-weight: 800;
  margin-bottom: 1rem;
}

.status-bar {
  background: #999;
  font-weight: 800;
  padding: 0.5rem 2rem;
}
</style>
