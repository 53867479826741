<template>
  <Carousel :settings="settings" :breakpoints="breakpoints" :autoplay="3000">
    <Slide v-for="project in projects" :key="project.title">
      <OurProjectsCard :project="project" class="carousel__item" />
    </Slide>

    <template #addons>
      <Navigation />
      <Pagination />
    </template>
  </Carousel>
</template>

<script>
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";

import OurProjectsCard from "./OurProjectsCard.vue";

export default {
  name: "OurProjectsCarousel",
  components: { Carousel, Slide, Pagination, Navigation, OurProjectsCard },
  data: function() {
    return {
      settings: { itemsToShow: 1, snapAlign: "center", wrapAround: true },
      breakpoints: {
        900: { itemsToShow: 2, snapAlign: "center" },
        400: { itemsToShow: 1, snapAlign: "center" }
      },

      projects: [
        {
          title: "AMRPL Site Hyderabad",
          desp:
            "Electricals Equipment of LT/HT, SWGR Cabling & Tray etc. Erection and Commissioning Job",
          status: "Completed",
          year: "2014"
        },
        {
          title: "NTPC Solapur",
          desp: "Electrical of TG Package Unit-I&II, 2X660 MW",
          status: "Completed",
          year: "2017"
        },
        {
          title: "NTPC Kahalgaon",
          desp: "C&I, Erection & Commissioning of 4 X 210MW R & M package",
          status: "Completed",
          year: "2015"
        },
        {
          title: "NTPC Solapur",
          desp: "C&I of TG Package Unit-I&II, 2X660 MW",
          status: "Completed",
          year: "2015"
        },
        {
          title: "JSTPS Uprvunl, Jawaharpur, UP",
          desp:
            "Erection, testing and commissioning of electrical and C&I works at 2X600",
          status: "On-goining",
          year: "-"
        },
        {
          title: "Barauni, Bihar",
          desp: "C&I works of WPT, DM & CPU Plant for HURL's Fertilizer Plan",
          status: "On-goining",
          year: "-"
        },
        {
          title: "Barauni, Bihar",
          desp:
            "Electrical works of WPT, DM & CPU Plant for HURL's Fertilizer Plan",
          status: "On-goining",
          year: "-"
        }
      ]
    };
  }
};
</script>

<style scoped>
.carousel__item {
  width: 100%;
  display: flex;
}
.carousel__slide {
  padding: 10px;
}
</style>

<style>
.carousel__prev,
.carousel__next {
  background: #f00 !important;
  box-sizing: content-box !important;
  border-radius: 0 !important;
  padding: 0.5rem !important;
}
.carousel__prev:hover,
.carousel__next:hover {
  background: #b00 !important;
}
.carousel__pagination-button {
  background: #fff !important;
}
.carousel__pagination-button.carousel__pagination-button--active {
  background: #f00 !important;
}

@media screen and (max-width: 800px) {
  .carousel__viewport {
    width: 300px;
  }
}
</style>
