<template>
  <div class="card-container">
    <div class="icon-container">
      <img class="icon" :src="content.icon" :alt="content.title" />
    </div>
    <div class="content-container">
      <div class="title-container">
        <span>{{ content.title }} </span>
      </div>
      <div class="desp-container">
        <p>
          {{ content.desp }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurServicesCard",
  props: ["content"]
};
</script>

<style scoped>
.card-container {
  display: flex;
  flex: 1 1 40%;
  padding: 2rem 3rem;
  margin: 0 1rem 1rem 0;
  transition: all 0.3s;
}

.card-container:hover {
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.icon-container {
  width: 2rem;
  margin-right: 1rem;
}

.icon-container img {
  width: 100%;
}

.content-container {
  flex: 1;
}

.title-container {
  font-weight: 800;
  margin-bottom: 1rem;
}

@media screen and (max-width: 800px) {
  .card-container {
    border: 1px solid #eee;
  }
}
</style>
