<template>
  <div class="container">
    <div class="hero-section wrapper">
      <img class="hero-img" :src="heroImg" />
      <div class="tagline-container">
        <h3 class="tagline">
          Wiring for the world's technology
        </h3>
        <p>
          We work with developers, facilities managers, construction firms, and
          a wide array of technical experts to manage successful projects.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import HeroImg from "../assets/hero-image.webp";

export default {
  name: "HeroSection",
  data: function() {
    return {
      heroImg: HeroImg
    };
  }
};
</script>

<style>
.hero-section {
  margin: 0;
  padding: 0;
  max-height: 90vh;
  min-height: 500px;
  max-width: 100% !important;
  overflow: hidden;
  position: relative;
}

.hero-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.tagline-container {
  bottom: 0;
  position: absolute;
  max-width: 45rem;
  height: 10rem;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 3rem;
  margin: auto;
  padding: 2rem 3rem;
  background: #fff;
}

.tagline {
  color: #223344;
  font-size: 2rem;
  font-weight: 800;
  font-family: "Work Sans", sans-serif;
  margin-bottom: 1rem;
}

@media screen and (max-width: 800px) {
  .tagline-container {
    background: rgba(0, 0, 0, 0.8);
    bottom: 2rem;
    height: auto;
    color: #fff;
    left: 0;
    right: 0;
    top: auto;
  }

  .tagline {
    color: #fff;
  }
}
</style>
