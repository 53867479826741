<template>
  <div class="container" id="our-services">
    <div class="wrapper">
      <h4 class="h4">Our Services</h4>
      <p class="para">
        SRE offers the complete range of construction to various industries.
      </p>
      <div class="card-container">
        <OurServicesCard
          :key="service.key"
          :content="service"
          v-for="service in services"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OurServicesCard from "./OurServicesCard.vue";
import ElectricalImage from "../../assets/electric-tower.png";
import ConstructionImage from "../../assets/construction.png";
import MechanicalImage from "../../assets/mechanical.png";
import FabricationImage from "../../assets/fabrication.png";
import HousekeepingImage from "../../assets/housekeeping.png";
import ManpowerImage from "../../assets/manpower.png";
import OtherImage from "../../assets/other.png";

export default {
  name: "OurServices",
  components: {
    OurServicesCard
  },

  data: function() {
    return {
      services: [
        {
          key: "0",
          title: "C&I Construction",
          icon: ConstructionImage,
          desp:
            "We offer to our clients a comprehensive range of services, but specially in Thermal Power Plant, Hydro Power Plant."
        },
        {
          key: "1",
          title: "Electrical Construction",
          icon: ElectricalImage,
          desp:
            "We work with developers, facilities managers, construction firms, and a wide array of technical experts to manage."
        },
        {
          key: "2",
          title: "Mechanical Construction",
          icon: MechanicalImage,
          desp:
            "We are able to provide the full range of mechanical services including validation, removal and installation."
        },
        {
          key: "3",
          title: "Fabrication",
          icon: FabricationImage,
          desp:
            "We offer expert design and engineering advice, coupled with extensive fabrication services to produce a wide range of products."
        },
        {
          key: "4",
          title: "Housekeeping",
          icon: HousekeepingImage,
          desp:
            "We provide highly trained housekeeping professional workforce to all market segments."
        },
        {
          key: "5",
          title: "Manpower Support",
          icon: ManpowerImage,
          desp:
            "As part of our full suite of services, we offer on-going support for a wide range of facilities needs."
        },
        {
          key: "6",
          title: "Other",
          icon: OtherImage,
          desp:
            "We provide civil for erection, installation, commissioning, testing, alignment and maintenance."
        }
      ]
    };
  }
};
</script>

<style scoped>
.para {
  font-size: 18px;
  margin-bottom: 4rem;
  text-align: center;
}

.container {
  background: #fff;
  display: flex;
  justify-content: center;
  padding: 2rem 3rem;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1300px;
}

@media screen and (max-width: 800px) {
  .card-container {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
</style>
