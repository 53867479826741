<template>
  <Navbar />
  <HeroSection />
  <AboutSection />
  <OurServices />
  <OurProjects />
  <OurClients />
  <ContactUs />
  <Footer />
</template>

<script>
import "vue3-carousel/dist/carousel.css";

import Navbar from "./components/Navbar.vue";
import HeroSection from "./components/HeroSection.vue";
import AboutSection from "./components/AboutSection.vue";
import OurServices from "./components/OurServicesSection/OurServicesSection.vue";
import OurProjects from "./components/OurProjectsSection/OurProjectsSection.vue";
import OurClients from "./components/OurClients.vue";
import ContactUs from "./components/ContactUs.vue";
import Footer from "./components/Footer.vue";

import "./common.css";

export default {
  name: "App",
  components: {
    Navbar,
    HeroSection,
    AboutSection,
    OurServices,
    OurProjects,
    OurClients,
    ContactUs,
    Footer
  },
  created() {
    document.title = "SR Electricals";
  }
};
</script>
