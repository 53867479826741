<template>
  <div class="container footer">
    <div class="wrapper">
      Copyright © 2021 SR Electricals Inc. All Rights Reserved.
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style>
.footer {
  background: #202020;
  padding: 1rem 2rem;
  color: #fff;
  font-size: 0.8rem;
}
</style>
