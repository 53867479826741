<template>
  <div class="container" id="clients">
    <div class="wrapper">
      <h4 class="h4">Our Clients</h4>
      <p class="para">
        We are privileged to be working with some of the most well known
        industries in India.
      </p>

      <div class="clients-wrapper">
        <img
          :src="client.img"
          v-for="client in clients"
          :key="client.id"
          class="client-img"
        />
      </div>
      <div class="info">
        All logos, and brands are property of their respective owners. Use of
        these names, logos, and brands does not imply endorsement
      </div>
    </div>
  </div>
</template>

<script>
import ABB from "../assets/abb.svg";
import Altsom from "../assets/altsom.svg";
import Driplex from "../assets/driplex.png";
import NTPC from "../assets/ntpc.svg";
import Simens from "../assets/simens.svg";
import WPIL from "../assets/wpil.png";
import Jyoti from "../assets/jyoti-turbo.png";
import DVC from "../assets/dvc.svg";
import KirloskarBorthers from "../assets/kirloskar-brothers.png";

export default {
  name: "OurClients",
  data() {
    return {
      clients: [
        { id: "abb", img: ABB },
        { id: "altsom", img: Altsom },
        { id: "driplex", img: Driplex },
        { id: "ntpc", img: NTPC },
        { id: "dvc", img: DVC },
        { id: "simens", img: Simens },
        { id: "kirloskar", img: KirloskarBorthers },
        { id: "wpil", img: WPIL },
        { id: "jyoti", img: Jyoti }
      ]
    };
  }
};
</script>

<style scoped>
.container {
  background: #fff;
  display: flex;
  justify-content: center;
  padding: 2rem 3rem;
}
.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
}
.para {
  text-align: center;
}

.clients-wrapper {
  max-width: 1300px;
  padding: 3rem;
  text-align: center;
}

.client-img {
  height: 3rem;
  margin: 4rem;
}

.info {
  font-size: 10px;
  margin-top: 2rem;
  color: #aaa;
}
@media screen and (max-width: 800px) {
  .client-img {
    margin: 2rem;
    height: 2rem;
  }
}
</style>
