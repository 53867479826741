<template>
  <nav class="navbar container" id="navbar">
    <div class="navbar-wrapper wrapper">
      <img
        @click="onNavbarItemClick('#home')"
        class="navbar-logo"
        :src="sreLogo"
      />
      <ul class="navbar-ul">
        <li class="navbar-li" :key="link.id" v-for="link in links">
          <a
            @click="onNavbarItemClick(link.id)"
            :href="link.href"
            class="navbar-button"
            :target="link.target"
          >
            {{ link.title }}
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import SRELogo from "../assets/logo.png";

export default {
  name: "Navbar",
  data: function() {
    return {
      sreLogo: SRELogo,
      links: [
        {
          title: "About us",
          id: "#about-us",
          href: "#about-us"
        },
        {
          title: "Our Services",
          id: "#our-services",
          href: "#our-services"
        },
        {
          title: "Projects",
          id: "#projects",
          href: "#projects"
        },
        {
          title: "Clients",
          id: "#clients",
          href: "#clients"
        },
        {
          title: "Contact Us",
          id: "#contact-us",
          href: "#contact-us"
        },
        {
          title: "SRE Portal",
          id: "#",
          href: "https://portal.srelectricals.net.in",
          target: "_blank"
        }
      ]
    };
  },

  navbar: null,
  navbarHeight: 0,
  methods: {
    onScroll: function() {
      if (this.navbar) {
        if (window.scrollY > 50) {
          this.navbar.classList.add("navbar-scroll");
        } else {
          this.navbar.classList.remove("navbar-scroll");
        }
      }
    },
    onNavbarItemClick: function(id) {
      if (id === "#home") {
        window.scrollTo(0, 0);
        return;
      }

      const element = document.querySelector(id);
      if (element) {
        const scrollY =
          window.pageYOffset +
          element.getBoundingClientRect().top -
          this.navbarHeight;
        window.scrollTo(0, scrollY);
      }
    }
  },
  mounted: function() {
    this.navbar = document.querySelector("#navbar");

    if (this.navbar) {
      this.navbarHeight = this.navbar.getBoundingClientRect().height;
    }
    document.addEventListener("scroll", this.onScroll);
  }
};
</script>

<style>
.navbar {
  background: transparent;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  transition: all 0.4s;
}

.navbar img {
  cursor: pointer;
}

.navbar-scroll {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
}

.navbar-wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.navbar-logo {
  height: 4rem;
  margin: 1rem 2rem;
}

.navbar-ul {
  display: inline-flex;
  height: 100%;
  list-style: none;
}

.navbar-button {
  align-items: center;
  background: transparent;
  border: none;
  color: #000;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  height: 100%;
  padding: 0 1rem;
  text-decoration: none;
}

.navbar-button:hover {
  background: rgba(0, 0, 0, 0.05);
}

@media screen and (max-width: 800px) {
  .navbar-ul {
    display: none;
  }

  .navbar-logo {
    height: 3.5rem;
  }
}
</style>
